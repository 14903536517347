import { h } from 'preact';
import { useStore } from 'effector-react';
import '../init';
import { PageHeader } from '../../../components/Typography/Typography';
import { Button } from '../../../components/Button/Button';
import { $cardCode } from '../../CardCode';
import { $fee, $sum, $isActiveFeeCalc } from '../../PaymentForm/model';
import { confirmPayment } from '../model';
import { fxAddPay } from '../../PaymentForm/fxAddPay';

export const Сonfirm = ({ onPrevStep, onStepComplete }) => {
  const cardCode = useStore($cardCode);
  const sum = useStore($sum);
  const fee = useStore($fee);
  const feeInProgress = useStore($isActiveFeeCalc);
  const isPaymentCreationInProgress = useStore(fxAddPay.pending);

  return isPaymentCreationInProgress ? (
    <div style={{ textAlign: 'center' }}>Загрузка...</div>
  ) : (
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <PageHeader>Подтвержение платежа</PageHeader>
      <p>
        Вы оплачиваете пополение транспортной карты № {cardCode} на сумму {sum} руб. Комиссия{' '}
        {feeInProgress ? 'рассчитывается...' : `${fee} руб`}
      </p>
      <p>
        После нажатия кнопки "Подтвердить", Вы будете перенаправлены на страницу банка для оплаты
      </p>

      <div>
        <Button look="secondary" onClick={onPrevStep}>
          Изменить
        </Button>{' '}
        <Button
          onClick={() => {
            confirmPayment();
            onStepComplete();
          }}
        >
          Подтвердить
        </Button>
      </div>
    </div>
  );
};
