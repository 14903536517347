import styles from './notification.module.css';

export const Notification = ({ id, type, title, descr, onClose }) => {
  return (
    <div className={styles.notification} data-type={type}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <button
          type="button"
          className={styles.closeButton}
          aria-click="hidden"
          onClick={onClose}
          data-id={id}
        >
          <span className={styles.hidden}>Скрыть уведомление</span>
        </button>
      </div>
      <div className={styles.desc}>{descr}</div>
    </div>
  );
};
