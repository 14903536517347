import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Router } from 'preact-router';
import 'normalize.css';
import './App.css';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';

import page from './page.module.css';

import { Home } from './Home/Home';
import { Payment } from '../features/PaymentForm/view/Payment';
import { Сonfirm } from '../features/Сonfirm/view/Confirm';
import { Redirect as RedirectView } from '../features/Redirect/view/Redirect';
import { Notifications } from '../features/Notifications/view/Notifications';

import CheckPage from '../routes/check';
import EmptyReceiptNumber from '../routes/check/EmptyReceiptNumber';

const Content = () => {
  const [step, setStep] = useState('home');
  // rerwite to independed stepper
  return (
    <>
      {step === 'home' && <Home onStepComplete={() => setStep('payment')} />}
      {step === 'payment' && (
        <Payment onPaymentSubmit={() => setStep('сonfirm')} onPrevStep={() => setStep('home')} />
      )}

      {step === 'сonfirm' && (
        <Сonfirm
          onPrevStep={() => setStep('payment')}
          onStepComplete={() => setStep('redirect')}
        />
      )}
      {step === 'redirect' && <RedirectView />}
    </>
  );
};

function App() {
  return (
    <div className={page.layout}>
      <Notifications />
      <Header />
      <div className={page.wrapper}>
        <Router>
          <Content path="/" />
          <EmptyReceiptNumber path="/check" />
          <CheckPage path="/check/:checkNumber" />
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
