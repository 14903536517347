import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { IMaskInput } from 'react-imask';

import home from '../../../components/Home/home.module.css';
import payment from './payment.module.css';
import { BackCard } from './BackCard';

import '../init';
import { fxProviderInfo } from '../fxProviderInfo'
import { fetchProviderInfo, changeSum, $fee, $sumIsDirty, $validatedSum, $isActiveFeeCalc, $sum, $email, changeEmail, $phone, changePhone } from '../model';
import { useStore } from 'effector-react';
import { $formatedCardCode } from '../../CardCode';
import { createPay } from '../../Сonfirm/model';


export const Payment = ({ onPrevStep, onPaymentSubmit }) => {
  const cardCode = useStore($formatedCardCode);
  const providerInfoLoading = useStore(fxProviderInfo.pending);
  const fee = useStore($fee);
  const sumIsDirty = useStore($sumIsDirty);
  const validatedSum = useStore($validatedSum);
  const isActiveFeeCalc = useStore($isActiveFeeCalc);
  const sum = useStore($sum);
  const email = useStore($email);
  const phone = useStore($phone);

  useEffect(() => {
    fetchProviderInfo();
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createPay();
        onPaymentSubmit({ });
      }}
    >
      <div className={payment.wrapper}>
        <BackCard cardCode={cardCode} />

        <div className={payment.form}>
          <h1 className={payment.header}>Параметры платежа</h1>
          <div className={payment.fields}>
            <label className={payment.field}>
              <span className={payment.title}>
                Введите сумму <br />
                <span className={payment.rub}>в рублях</span>
              </span>

                <IMaskInput
                  mask={Number}
                  type="text"
                  value={sum}
                  name="amount"
                  scale={2}
                  radix="."
                  unmask={true}
                  placeholder="0.00"
                  required
                  autofocus
                  onAccept={changeSum}
                  disabled={providerInfoLoading}
                  className={payment.input}
                />
            </label>

            <div style={{ color: '#a64b4b'}}>
              {sumIsDirty && validatedSum?.error === 'zero' ? 'Сумма не может быть 0 р.' : null }
              {sumIsDirty && validatedSum?.error === 'maxSum'? `Сумма не может быть больше ${validatedSum.value} р.` : null }
              {sumIsDirty && validatedSum?.error === 'minSum'? `Сумма не может быть меньше ${validatedSum.value} р.` : null }
            </div>

            <div className={payment.title}>Комиссия: {isActiveFeeCalc ? 'Расчитываем...' : `${fee} р.`}</div>

            <p className={payment.title}>
              Для получения кассового чека, пожалуйста, введите Ваши данные:
            </p>

            <label className={payment.field}>
              <span className={payment.title}>E-mail</span>
              <input
                type="email"
                value={email}
                onChange={(e) => changeEmail(e.target.value)}
                className={payment.input}
              />
            </label>

            <label className={payment.field}>
              <span className={payment.title}>Номер мобильного телефона</span>
              <IMaskInput
                mask="+7(000) 000 00 00"
                type="text"
                value={phone}
                unmask={true}
                onAccept={changePhone}
                className={payment.input}
                placeholder=""
              />
            </label>
          </div>
        </div>
      </div>
      <div className={payment.submitWrapper}>
        <button type="button" onClick={onPrevStep} className={home.submit} style={{ color: '#475062', backgroundColor: 'white'}}>
          Изменить номер карты
        </button>
        {' '}
        <button type="submit" className={home.submit}>
          Оплатить
        </button>
      </div>
    </form>
  );
};
