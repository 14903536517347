import { createEffect } from 'effector/compat';
import { confirmPay } from '../../api';

export const fxConfirmPay = createEffect((payment) =>
  confirmPay({
    body: {
      ...payment,
      agentInfo: {
        agentId: 4,
        returnUrl: `https://platilka.online/check/${payment.checkNumber}/`
      }
    },
  }),
);

