import { h } from 'preact';
import header from './header.module.css';

export const Header = () => {
  return (
    <header className={header.header}>
      <div className={header.wrapper}>
        <a href="/" className={header.logo}>
          <img
            src="https://reg-kursk.ru/upload/000/u1/000/382b7569.png"
            width="150"
            height="84"
            alt="Логотип сайта"
          />
        </a>
      </div>
    </header>
  );
};
