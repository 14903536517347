import { h } from 'preact';
import footer from './footer.module.css';

export const Footer = () => {
  return (
    <footer className={footer.footer}>
      <div className={footer.wrapper}>
        <div className={footer.supportPhone}>
          Телефоны поддержки по вопросам пополнения: <br />
          <a href="tel:+74712395152">+7 (4712) 39-51-52</a>,<a href="tel:395153">39-51-53</a>
        </div>
        <div className={footer.infoLinks}>
          <a href="https://reg-kursk.ru/ascop">
            Автоматизированная система контроля оплаты проезда Курской области
          </a>
          <br />
          <a href="http://reg-kursk.ru">
            Государственное унитарное предприятие Курской области <br /> "Информационный центр
            "Регион-Курск"
          </a>
        </div>
        <div className={footer.logotypes}>
          <img src="/assets/payments-logo.png" alt="Payment system logo's" />
        </div>
      </div>
    </footer>
  );
};