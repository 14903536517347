import { h } from 'preact';
import payment from './payment.module.css';

export const BackCard = ({ cardCode }) => (
  <div className={payment.infoCard}>
    <div className={payment.card}>
      <div className={payment.cardTitle}>Номер транспортной карты</div>
      <div className={payment.cardCode}>{cardCode.match(/.{1,3}/g)?.join(' ')}</div>
      <div className={payment.cardSerial}>серия 10</div>
      <div className={payment.cardExpired}>Действует до 14.07.21</div>
    </div>
    {/* <div className={payment.balance}>
  Баланс: <b>485.00 р.</b>
</div> */}
  </div>
);
