import { createEffect, createEvent } from 'effector/compat';
import { calcPayCommission } from '../../api'

export const abortFxCalcFee = createEvent();
export const fxCalcFee = createEffect((sum) => new Promise((resolve, reject) => {
  const unwatch = abortFxCalcFee.watch(() => {
    unwatch();
    reject(new Error('AbortError'))
  })
  calcPayCommission({ body: { providerId: 18762, summ: sum } })
    .then(resolve, reject)
    .finally(unwatch);
  })
);
