import { createEffect } from 'effector/compat';
import { preparePay } from '../../api';
// import { ApiMessage, JsonParseError, ServerError, ConnectionError } from '../../api/Exceptions';

export const fxProviderInfo = createEffect(() => preparePay({ body: { providerId: 18762 } }));

// export const {
//   apiMessageError,
//   jsonParseError,
//   serverError,
//   connectionError,
//   __: unknownError,
// } = split(fxProviderInfo.failData, {
//   apiMessage: (error) => error instanceof ApiMessage,
//   jsonParseError: (error) => error instanceof JsonParseError,
//   ServerError: (error) => error instanceof ServerError,
//   ConnectionError: (error) => error instanceof ConnectionError,
// });
