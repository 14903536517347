import { sample, forward } from 'effector/compat';
import { $formatedCardCode } from '../CardCode/model';
import { fxAddPay } from '../PaymentForm/fxAddPay';
import { $email, $phone, $sum } from '../PaymentForm/model';
import { changeCreatedPayment, confirmPayment, createPay, $createdPayment } from './model';
import { fxConfirmPay } from './fxConfirmPay';
import { showError } from '../Notifications/model';

sample({
  clock: createPay,
  source: {
    code: $formatedCardCode,
    sum: $sum,
    email: $email,
    phone: $phone,
  },
  target: fxAddPay,
});

forward({
  from: fxAddPay.doneData,
  to: changeCreatedPayment,
});

sample({
  source: fxAddPay.failData,
  target: showError,
  fn: ({ message }) => ({
    title: 'При создании платежа произошла ошибка',
    descr: message,
  }),
});

sample({
  clock: confirmPayment,
  source: $createdPayment,
  target: fxConfirmPay,
});

fxConfirmPay.doneData.watch((payment) => {
  window.location = payment.agentInfo.confirmationUrl;
});

sample({
  source: fxConfirmPay.failData,
  target: showError,
  fn: ({ message }) => ({
    title: 'При переходе на страницу банка произошла ошибка',
    descr: message,
  }),
});
