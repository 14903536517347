import { createEvent, createStore, restore, combine } from 'effector/compat';

export const fetchProviderInfo = createEvent();
export const changeProviderInfo = createEvent();
export const $providerInfo = restore(changeProviderInfo, {
  termInfo: null,
  provider: null,
  pay: {
    minSumm: null,
    maxSumm: null,
  },
});


export const changeSum = createEvent();
export const $sum = restore(changeSum, '');

export const $sumIsDirty = createStore(false).on(changeSum, () => true);
export const $validatedSum = combine($sum, $providerInfo, (sum, { pay }) => {
  if (sum <= 0) return { error: 'zero' };
  if (sum > pay.maxSumm) return { error: 'maxSum', value: pay.maxSumm };
  if (sum < pay.minSumm) return { error: 'minSum', value: pay.minSumm };

  return null;
});
export const $isValidSum = $validatedSum.map((error) => !error);
export const $isInvalidSum = $validatedSum.map(Boolean);


export const changeFee = createEvent();
export const resetFee = createEvent();
export const $fee = createStore(0)
  .on(changeFee, (_, fee) => fee)
  .reset(resetFee);


export const showCalcFeeProgress = createEvent();
export const hideCalcFeeProgress = createEvent();
export const $isActiveFeeCalc = createStore(false)
  .on(showCalcFeeProgress, () => true)
  .on(hideCalcFeeProgress, () => false);


export const changePhone = createEvent();
export const $phone = restore(changePhone, '');

export const changeEmail = createEvent();
export const $email = restore(changeEmail, '');