import { forward, guard, sample } from 'effector/compat';
import { debounce } from 'patronum/debounce';
import {
  fetchProviderInfo,
  changeProviderInfo,
  $providerInfo,
  changeSum,
  $isValidSum,
  $isInvalidSum,
  changeFee,
  resetFee,
  showCalcFeeProgress,
  hideCalcFeeProgress,
} from './model';
import { fxCalcFee, abortFxCalcFee } from './fxCalcFee';
import { fxProviderInfo } from './fxProviderInfo';
import { showError } from '../Notifications/model';

guard({
  source: fetchProviderInfo,
  filter: $providerInfo.map((store) => store.provider === null),
  target: fxProviderInfo,
});

forward({
  from: fxProviderInfo.doneData,
  to: changeProviderInfo,
});

sample({
  source: fxProviderInfo.failData,
  target: showError,
  fn: ({ message }) => ({
    title: 'При загрузке произошла ошибка',
    descr: message,
  }),
});

guard({
  source: changeSum,
  filter: fxCalcFee.pending,
  target: abortFxCalcFee,
});

forward({
  from: [changeSum],
  to: showCalcFeeProgress,
});

guard({
  source: changeSum,
  filter: $isInvalidSum,
  target: [
    resetFee,
    hideCalcFeeProgress,
    /* теперь есть отмена выше во всех кейсах abortFxCalcFee*/
  ],
});

guard({
  source: debounce({
    source: changeSum,
    timeout: 1000,
  }),
  filter: $isValidSum,
  target: fxCalcFee,
});

sample({
  source: fxCalcFee.failData,
  target: showError,
  fn: ({ message }) => ({
    title: 'При расчете комиссии произошла ошибка',
    descr: message,
  }),
});

guard({
  source: fxCalcFee.doneData,
  filter: $isValidSum,
  target: changeFee.prepend((fee) => fee.commission),
});

forward({
  from: [
    fxCalcFee.done,
    fxCalcFee.fail.filter({ fn: (error) => error?.massage === 'AbortError' }),
  ],
  to: hideCalcFeeProgress,
});
