import { useStore } from 'effector-react';
import { $notifications, removeNotification } from '../model';
import { Notification } from './Notification';
import styles from './notifications.modules.css';

const removeNotif = (event) => removeNotification({ id: event.target.dataset.id });

export const Notifications = () => {
  const notifications = useStore($notifications);
  return (
    <div className={styles.notifications}>
      <div className={styles.children} onClick={removeNotif}>
        {notifications.map(({ id, type, title, descr }) => (
          <Notification key={id} id={id} type={type} title={title} descr={descr} />
        ))}
      </div>
    </div>
  );
};
