import { createEffect } from 'effector/compat';
import { addPay } from '../../api';
import { TERMINAL_ID } from '../../api/terminal'

export const fxAddPay = createEffect(({ code = '', sum, email = null, phone = null}) =>
  addPay({
    body: {
      providerId: 18762,
      terminalId: TERMINAL_ID,
      payDate: new Date(),
      code,
      params: { phone: code },
      currencyId: 643,
      fiscalInfo: { email, phone },
      summ: Number(sum),
    },
  }),
);

// {
//   "terminalId": 47970,
//   "providerId": 14193,
//   "payDate": "2020-11-26T17:38:33+03:00",
//   "code": "9999609109",
//   "params": { "phone": "9999609109" },
//   "currencyId": 643,
//   "fiscalInfo": { "email": "as@asd.ru" },
//   "summToPay": 9.6
// }
