import { createEvent, createStore, forward } from 'effector';
import { delay } from 'patronum/delay';

let idGen = 0;

const addNotification = createEvent();
export const removeNotification = createEvent();

forward({
  from: delay({
    source: addNotification,
    timeout: 500000,
  }),
  to: removeNotification,
});

export const $notifications = createStore([])
  .on(addNotification, (state, notif) => state.concat(notif))
  .on(removeNotification, (state, { id }) => state.filter((notif) => notif.id !== Number(id)));

export const showError = addNotification.prepend(({ title, descr }) => ({
  id: ++idGen,
  type: 'error',
  title: title || 'Произошла ошибка',
  descr,
}));
