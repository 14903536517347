import home from './home.module.css';
import { FrontCard } from '../../features/CardCode/view/FrontCard';

export const Home = ({ onStepComplete }) => {
  return (
    <form
      className={home.wrapper}
      onSubmit={(e) => {
        e.preventDefault();
        onStepComplete();
      }}
    >
      <FrontCard />
      <button type="submit" className={home.submit}>
        Продолжить
      </button>
    </form>
  );
};
