import { h } from 'preact';
import { IMaskInput } from 'react-imask';

import card from './frontCard.module.css';

import { changeCardCode, $cardCode } from '../index';
import { useStore } from 'effector-react';

export const FrontCard = () => {
  const code = useStore($cardCode);
  // const [code, setCode] = useState('');

  return (
    <>
      <span className={`${card.fieldTitle} ${card.smallSize}`}>
        Введите номер траспортной карты:
    </span>

      <div className={card.main}>
        <label className={card.field}>
          <span className={`${card.fieldTitle} ${card.largeSize}`}>
            Введите номер траспортной карты:
        </span>
          <IMaskInput
            mask="000 000 000"
            type="text"
            value={code}
            name="code"
            unmask={true}
            placeholder="012 345 678"
            required
            autofocus
            onAccept={changeCardCode}
            // onAccept={setCode}
            className={card.input}
          />
          <span className={`${card.numberInfo} ${card.largeSize}`}>
            Номер карты напечатан на обратной стороне
        </span>
        </label>
      </div>
      <span className={`${card.numberInfo} ${card.smallSize}`}>
        Номер карты напечатан на обратной стороне
    </span>
    </>
  )
}
