import { ServerError } from './Exceptions/ServerError';
import { JsonParseError } from './Exceptions/JsonParseError';
import { ApiMessage } from './Exceptions/ApiMessage';
import { ConnectionError } from './Exceptions/ConnectionError';
import { RequestError } from './Exceptions/RequestError';

export const createResource = ({ url, method = 'GET' }) => async ({ body, query }) => {
  const computedUrl = new URL(url, window.location);
  if (query) {
    computedUrl.search = new URLSearchParams(query);
  }

  // eslint-disable-next-line no-useless-catch
  try {
    const res = await fetch(computedUrl, {
      method,
      headers: {
        'content-type': 'application/json',
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (!res.ok)
      throw new ServerError({
        res,
        req: { url: computedUrl, method, body, query },
      });

    let json;
    try {
      json = await res.json();
    } catch (error) {
      throw new JsonParseError({
        res,
        req: { url: computedUrl, method, body, query },
        debug: error,
      });
    }
    if (json?.result > 0) throw new ApiMessage(json);

    return json;
  } catch (error) {
    if (error instanceof RequestError) throw error;
    throw new ConnectionError();
  }
};
