import { RequestError } from './RequestError';

export class JsonParseError extends RequestError {
  constructor(params = {}) {
    super(params);

    const { debug } = params;

    this.setMessage('Ошибка сервера', 'json');
    this.debug = debug;
  }
}
